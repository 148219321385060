<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="800px"
      persistent
    >
      <!-- Loading spinner -->
      <div class="text-center" v-if="loading" style="min-height: 600px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="margin-top: 200px"
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <!-- Content for the dialog -->
      <v-card v-else background-color="#f2f2f2" style="height: auto">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="dialogTitle-text" style="font-size: 28px">
            Ticket Revenue Details
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            class="close-btn"
            color="white"
            @click="toggleModal({ Delete: false })"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text style="padding: 20px">
          <!-- Table -->
          <v-simple-table
            class="elevation-8"
            style="margin-top: 2%; width: 100%; margin-bottom: 1%"
          >
            <template v-slot:default>
              <thead>
                <tr style="background: #e7e5ed">
                  <th class="textHeader text-left" style="font-size: 17px">
                    Answer
                  </th>
                  <th class="textHeader text-center" style="font-size: 17px">
                    Count
                  </th>
                  <th class="textHeader text-center" style="font-size: 17px">
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Dynamic rows -->
                <tr v-for="(itemhost, i) in hostTicketSold" :key="i">
                  <td class="text-left">{{ itemhost.school }}</td>
                  <td class="text-center">{{ itemhost.ticket_sold }}</td>
                  <td class="text-center">{{ itemhost.percentage }}%</td>
                </tr>

                <!-- Static Total Row -->
                <tr style="background: #f4f4f4; font-weight: bold">
                  <td class="text-left">Sum</td>
                  <td class="text-center">
                    {{
                      hostTicketSold.reduce(
                        (total, itemhost) => total + itemhost.ticket_sold,
                        0
                      )
                    }}
                  </td>
                  <td class="text-center">100%</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            class="footerStyle"
            style="font-size: 13px; margin-bottom: 3%"
            v-if="this.hostticketFlag"
          >
            Last update on {{ lastupdatedhostdate }} at
            {{ lastupdatedhosttime }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_GAME_HOST_TICKET_SOLD } from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TicketRevenueModal",
  data() {
    return {
      loading: false,
      hostTicketSold: [],
      hostticketFlag: false,
      lastupdatedhosttime: "",
      lastupdatedhostdate: "",
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "gameManagement/getTicketRevenueShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        this.toggleTicketRevenueModal({ show: value });
      },
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "gameManagement/toggleTicketRevenueModal",
    }),
    getHostTicketData() {
      const successHandler = (res) => {
        this.hostTicketSold = res.data.order_info;
        if (this.hostTicketSold.length != 0) {
          this.hostticketFlag = res.data.data_available;
          this.lastupdatedhosttime = res.data.last_update_on.time;
          this.lastupdatedhostdate = res.data.last_update_on.date;
        }
        //alert(this.hostticketFlag);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.$emit("reload");
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      Axios.request_GET(
        API_GAME_HOST_TICKET_SOLD,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
  },
  mounted() {
    this.getHostTicketData();
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>
